<template>
  <section class='section'>
    <modal-groups
      v-if="modalGroups"
      :close="showModal"
      :userGroupsList="userGroupsList"
      :createUserGroup="createUserGroup"
      :loadingAdd="loadingAdd"
    />
    <nav class="level">
      <div class="level-left">
        <div class="level-item">
          <h1>Usuarios: {{ totalProducts }}</h1>
        </div>
      </div>
      <div class="level-left">
      </div>
      <div class="level-right">
        <div class="level-item">
          <button
            class="button"
            type="button"
            :class="{ 'is-loading': isLoading }"
            @click="getCsvDownload"
          >
          Descargar
        </button>
        </div>
        <div class="level-item">
          <div class="control">
            <input class="input" type="text" v-model="search" @keyup="searchUser" placeholder="Buscador...">
          </div>
        </div>
        <div class="level-item">
          <div class="select is-fullwidth">
            <select class="select" v-model="filterGroup" @change="filterByGroup">
              <option value="">Todos</option>
              <template v-for="group in userGroupsList">
                  <option :key="group.id" :value="group.id"> {{group.name}} </option>
                </template>
            </select>
          </div>
        </div>
        <div class="level-item">
          <button @click="toggleDatePicker" v-if="!showDatePicker" class="button">
            Filtrar por fecha
          </button>
          <div v-else style="max-width: 150px; overflow: hidden;">
            <v-date-picker
              mode="range"
              v-model="date"
              :max-date="today"
              @input="onDateChange"
            />
          </div>
          <button @click="resetDate" v-if="showDatePicker" class="button" style="padding: 4px;">
            <span class="material-icons">
              restart_alt
            </span>
          </button>
        </div>
        <div class="level-item">
          <button class="button is-info" @click="showModal"> Grupos </button>
        </div>
      </div>
    </nav>
    <div class="columns is-multiline">
      <div class="column is-12">
        <base-table
          class="is-bordered is-striped is-narrow is-fullwidth is-hoverable bankingTable"
          :columns="columnsData"
          :data="usersList"
          :loading=loading
        >
        <div
          class="field"
          slot="config"
          slot-scope="scope"
        >
          <input
            :id="`switchStatus-${scope.row.id}`"
            type="checkbox"
            name="switchStatusUser"
            class="switch is-rounded is-info is-small"
            :checked="!scope.row.blocked ? 'checked' : ''"
            @click="deactivateUser(scope.row)"
          >
          <label
            :for="`switchStatus-${scope.row.id}`"
          >
          </label>
        </div>
        </base-table>
      </div>
    </div>
    <base-pagination
      class="is-medium"
      :page="page"
      :pages="parseInt(pages)"
      :action="paginator"
      :loading="loading"
    />
  </section>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import moment from 'moment'
import mixin from '../../mixin/validateDate'
// const DEFAULT_DISPLAY_SIZE = 10

export default {
  mixins: [mixin],
  name: 'Usuarios',
  components: {
    BaseTable: () => import('@/components/Table'),
    BasePagination: () => import('@/components/BasePagination'),
    ModalGroups: () => import('@/components/Customers/ModalGroups')
  },
  data () {
    return {
      search: '',
      page: 1,
      pages: '',
      totalProducts: 0,
      loading: false,
      isLoading: false,
      usersList: [],
      date: {},
      today: moment().add(1, 'days').format('YYYY-MM-DD'),
      showDatePicker: false,
      columnsData: [
        {
          id: 'id',
          header: 'Id',
          class: 'column-mobile'
        },
        {
          id: 'name',
          header: 'Usuario',
          class: 'column-mobile',
          type: 'link',
          link: true,
          route: (row) => `usuarios/detalle/${row.id}`
        },
        { id: 'email', header: 'Correo', class: 'is-hidden-touch' },
        { id: 'phone', header: 'Télefono', class: 'is-hidden-touch' },
        {
          id: 'created_at',
          header: 'Fecha',
          class: 'column-mobile',
          accessor: (row) => moment(row.createdAt).format('DD-MM-YYYY')
        },
        {
          id: 'birthdate',
          header: 'Fecha de Nacimiento',
          class: 'column-mobile',
          accessor: (row) => {
            if (row.birthdate && moment(row.birthdate).isValid()) {
              return moment(row.birthdate).format('DD-MM-YYYY')
            } else {
              return 'El usuario no proporciono la fecha'
            }
          }
        },
        { id: 'balance', header: '$ (Recompensa)', class: 'column-mobile', accessor: (row) => this.numberFormat(row.balance) },
        { id: 'totalproducts', header: 'Productos', class: 'column-mobile', accessor: (row) => row.totalproducts || 0 },
        { id: 'totalsales', header: '# de Ventas', class: 'column-mobile', accessor: (row) => row.totalsales || 0 },
        { id: 'totalpurchases', header: '# de Compras', class: 'column-mobile', accessor: (row) => row.totalpurchases || 0 },
        {
          id: 'status',
          header: 'Bloqueado',
          accessor: (row) => row.blocked ? 'Si' : 'No'
        },
        {
          id: 'config',
          name: 'config',
          header: 'Activo',
          class: 'column-mobile has-text-centered'
        }
      ],
      modalGroups: false,
      userGroupsList: [],
      loadingAdd: false,
      filterGroup: '',
      startDate: '',
      endDate: ''
    }
  },
  methods: {
    ...mapMutations(['setAuthenticationValue']),
    ...mapActions(['getCustomers', 'userUpdateStatus', 'getUsergroups', 'createUsergroups', 'csvDownloadUsers', 'addTemporalNotification']),
    paginator (page) {
      this.page = page
      this.loading = true

      const { filter, search, startDate, endDate } = this.$router.history.current.query
      this.filterGroup = filter === '' || filter === undefined ? this.filterGroup : parseInt(filter, 10)
      this.search = search === '' ? this.search : search
      this.startDate = startDate === '' ? this.startDate : startDate
      this.endDate = endDate === '' ? this.endDate : endDate

      this.$router.push({ name: 'usuarios', query: { page: page, filter: this.filterGroup, search: this.search, startDate: this.startDate, endDate: this.endDate } })

      this.getCustomers({ page, search: this.search, filterGroup: this.filterGroup, startDate: this.startDate, endDate: this.endDate })
        .then(resp => {
          this.loading = false
          this.usersList = resp.payload
          this.pages = resp.totalPages
          this.totalProducts = resp.total
        })
    },
    searchUser ({ target }) {
      this.page = 1
      if (target.value.length >= 3 || target.value.length === 0) {
        this.search = target.value
        this.$router.push({ name: 'usuarios', query: { page: this.page, search: target.value, filter: this.filterGroup, startDate: this.startDate, endDate: this.endDate } })
        this.getCustomers({ page: this.page, search: target.value, filterGroup: this.filterGroup, startDate: this.startDate, endDate: this.endDate })
          .then(resp => {
            this.usersList = resp.payload
            this.pages = resp.totalPages
            this.totalProducts = resp.total
            this.loading = false
          })
      }
    },
    deactivateUser (data) {
      let statusUpdate = !data.blocked
      let userId = data.id
      let dataUpdate = { statusUpdate, userId }
      this.userUpdateStatus(dataUpdate)
        .then(resp => {
          this.loading = true
          this.getCustomers({ page: 1, search: '' })
            .then(resp => {
              this.usersList = resp.payload
              this.pages = resp.totalPages
              this.totalProducts = resp.total
              this.loading = false
            })
        })
    },
    showModal () {
      this.modalGroups = !this.modalGroups
    },
    createUserGroup (data) {
      this.loadingAdd = true
      return this.createUsergroups(data)
        .then(response => {
          return this.getUsergroups()
            .then(groups => {
              this.userGroupsList = groups
              this.loadingAdd = false
              return true
            })
        })
    },
    filterByGroup ({ target }) {
      this.page = 1
      this.$router.push({ name: 'usuarios', query: { page: this.page, search: this.search, filter: target.value } })
      this.getCustomers({ page: this.page, search: this.search, filterGroup: target.value })
        .then(resp => {
          this.usersList = resp.payload
          this.pages = resp.totalPages
          this.totalProducts = resp.total
          this.loading = false
        })
    },
    getCsvDownload () {
      this.isLoading = true
      this.csvDownloadUsers({ search: this.search, filterGroup: this.filterGroup, startDate: this.startDate, endDate: this.endDate })
        .then(resp => {
          if (resp) {
            const exportName = 'Export users.csv'
            console.log(resp, 'test')
            const blob = new Blob([resp], { type: 'text/csv;charset=utf-8;' })
            if (navigator.msSaveBlob) {
              navigator.msSaveBlob(blob, exportName)
            } else {
              const link = document.createElement('a')
              if (link.download !== undefined) {
                const url = URL.createObjectURL(blob)
                link.setAttribute('href', url)
                link.setAttribute('download', exportName)
                link.style.visibility = 'hidden'
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
              }
            }
          } else {
            this.addTemporalNotification({ type: 'danger', message: 'Error en la descarga' })
          }
          this.isLoading = false
        })
    },
    onDateChange (newDate) {
      if (newDate == null) return null

      this.startDate = moment(newDate?.start).format('YYYY-MM-DD')
      this.endDate = moment(newDate?.end).format('YYYY-MM-DD')

      this.$router.push({ name: 'usuarios', query: { page: this.page, search: this.value, filter: this.filterGroup, startDate: this.startDate, endDate: this.endDate } })

      this.getCustomers({ page: 1, search: this.search, startDate: this.startDate, endDate: this.endDate })
        .then(resp => {
          this.usersList = resp.payload
          this.pages = resp.totalPages
          this.totalProducts = resp.total
          this.loading = false
        })
    },
    toggleDatePicker () {
      this.showDatePicker = !this.showDatePicker
    },
    resetDate () {
      this.date = {}
      this.showDatePicker = false
      this.startDate = ''
      this.endDate = ''
      this.page = 1

      this.$router.push({ name: 'usuarios', query: { page: this.page, search: this.value, filter: this.filterGroup, startDate: this.startDate, endDate: this.endDate } })
      this.getCustomers({ page: 1, search: this.search, startDate: this.startDate, endDate: this.endDate })
        .then(resp => {
          this.usersList = resp.payload
          this.pages = resp.totalPages
          this.totalProducts = resp.total
          this.loading = false
        })
    }
  },
  beforeMount () {
    this.loading = true
    const { page, filter, search, startDate, endDate } = this.$router.history.current.query
    this.page = (page === '' || page === undefined) ? this.page : parseInt(page, 10)
    this.filterGroup = filter === '' || filter === undefined ? this.filterGroup : parseInt(filter, 10)
    this.search = search === '' ? this.search : search
    this.startDate = startDate === '' ? this.startDate : startDate
    this.endDate = endDate === '' ? this.endDate : endDate

    this.getCustomers({ page: this.page, search: this.search, startDate: this.startDate, endDate: this.endDate })
      .then(resp => {
        this.usersList = resp.payload
        this.pages = resp.totalPages
        this.totalProducts = resp.total
        this.loading = false
      })
    this.getUsergroups()
      .then(groups => {
        this.userGroupsList = groups
      })
  }
}
</script>
<style scoped>
  h2 {
    margin-top: 40px;
    margin-bottom: 40px;
    text-align: center;
    font-weight: 700;
  }
  .section {
    width: 100%;
    padding: 0 20px 0 20px;
  }
</style>
